/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pump_0': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" _fill="#F4EBD9" d="M20.272 30.875l3.215 2.869a.44.44 0 01.042.608.414.414 0 01-.316.148h-6.387a.425.425 0 01-.419-.43c0-.136.063-.264.168-.345l3.697-2.85zM20 5.5c7.081 0 12.385 5.882 11.978 12.954-.266 4.62-4.11 9.182-11.44 14.536-.478-1.188-.68-1.81-.607-1.864 6.613-4.916 9.987-8.957 10.207-12.781.346-6.012-4.13-10.976-10.138-10.976S9.516 12.333 9.862 18.345c.197 3.427 2.559 6.208 7.905 10.533l-1.604 1.09c-5.42-4.484-7.913-7.55-8.141-11.514C7.615 11.382 12.919 5.5 20 5.5zm.22 3.927c4.43 0 7.952 3.632 7.952 8.005h-1.85c0-3.355-2.704-6.143-6.101-6.143V9.427z"/>'
  }
})
